<template>
    <div>
        <section v-for="(section, index) in modules" class :key="index">
            <!-- textualna sekcija -->
            <div
                v-if="section.acf_fc_layout === 'textual_module'"
                class="text-section bg-black text-white my-5 my-lg-6 container-longer container-fluid p-4 p-md-5"
                v-in-viewport.once
            >
                <div class="fade-item">
                    <div class="row">
                        <div class="col-12">
                            <h2
                                :style="{
                                    color: color
                                }"
                                class="mb-4 font-weight-bold"
                            >
                                {{ section.title }}
                            </h2>
                            <div v-html="section.text" class="text"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- galerija -->
            <div
                v-if="section.acf_fc_layout === 'gallery_module'"
                class="gallery-section text-white container-longer mobile-fullscreen"
            >
                <div
                    class="gallery-slider"
                    :data-btn-color="section.buttons_color"
                >
                    <b-carousel
                        fade
                        indicators
                        :interval="4000"
                        @sliding-end="slidingEnd"
                    >
                        <b-carousel-slide
                            v-for="(img, index) in section.gallery_image"
                            :key="index"
                        >
                            <img
                                slot="img"
                                class="d-block img-fluid w-100"
                                :src="img.image"
                                :alt="img.image_title"
                            />
                        </b-carousel-slide>
                    </b-carousel>
                </div>
            </div>
            <!-- side by side sekcija -->
            <div
                v-if="section.acf_fc_layout === 'side_by_side_module'"
                class="side-by-side-section text-white container-longer container-fluid"
            >
                <div class="row no-gutters">
                    <div class="col-6">
                        <img
                            :src="section.left_image"
                            :alt="section.left_image_title"
                            class="img-fluid"
                        />
                    </div>
                    <div class="col-6">
                        <img
                            :src="section.right_image"
                            :alt="section.right_image_title"
                            class="img-fluid"
                        />
                    </div>
                </div>
            </div>
            <!-- video sekcija -->
            <div
                v-if="section.acf_fc_layout === 'video_module'"
                class="text-white container-longer"
            >
                <video-comp
                    :video_tag_id="'videoModularSection-' + index"
                    :video_id="section.video_url"
                    :video_img="section.video_image.url"
                    :btn_color="color"
                ></video-comp>
            </div>
            <!-- fullscreen sekcija -->
            <div
                v-if="section.acf_fc_layout === 'fullscreen_image_module'"
                class="container-longer container-fluid text-white"
            >
                <div class="gallery-slider">
                    <b-carousel>
                        <b-carousel-slide>
                            <img
                                slot="img"
                                class="d-block img-fluid w-100"
                                :src="section.fullscreen_image"
                                :alt="section.fullscreen_image_text"
                            />
                        </b-carousel-slide>
                    </b-carousel>
                </div>
            </div>
            <!-- side-text sekcija -->
            <div
                v-if="section.acf_fc_layout === 'side_text_module'"
                class="side-text-section text-white container-longer"
            >
                <div
                    class="row no-gutters"
                    :class="'image-' + section.left_right_image_position"
                >
                    <div class="img-wrapper col-12 col-lg-6">
                        <img
                            class="img-fluid object-cover object-center"
                            :src="section.image"
                            :alt="section.image_title"
                        />
                    </div>
                    <div class="img-description bg-black col-12 col-lg-6">
                        <div class="p-4 p-md-5">
                            <h2
                                :style="{
                                    color: color
                                }"
                                class="mb-4 font-weight-bold"
                            >
                                {{ section.title }}
                            </h2>
                            <div v-html="section.short_text" class="text"></div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- empty-module sekcija -->
            <div
                v-if="section.acf_fc_layout === 'empty_module'"
                class="empty-section"
            ></div>
        </section>
    </div>
</template>

<script>
import Video from "@/components/Video.vue";
export default {
    props: {
        modules: {
            type: Array,
            default: () => [],
            required: true
        },
        color: {
            type: String,
            default: "white",
            required: false
        }
    },
    components: {
        videoComp: Video
    },
    methods: {
        slidingEnd() {
            $(".gallery-slider").each(function(index, slider) {
                $(slider)
                    .find(".carousel-indicators li")
                    .css("background-color", "transparent");
                $(slider)
                    .find(".carousel-indicators li.active")
                    .css("background-color", $(slider).attr("data-btn-color"));
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
