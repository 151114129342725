<template>
    <div class="project-main-info bg-grayish" v-in-viewport.once>
        <div class="container-longer container-fluid py-5 fade-item">
            <h2 class="mb-0 font-weight-bold" :style="{ color: color }">
                {{ subtitle }}
            </h2>
            <h1 class="text-white h2 font-weight-bold">
                {{ title }}
            </h1>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: true
        },
        color: {
            type: String,
            default: "white",
            required: false
        }
    }
};
</script>

<style lang="scss" scoped></style>
