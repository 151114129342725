<template>
    <div
        class="fullscreen-section embed-section position-relative bg-primary"
        :class="[isPlaying ? 'playing' : '', imgVisible ? '' : 'hideImg']"
    >
        <template v-if="video_id">
            <div class="embed-inner">
                <iframe
                    :src="
                        `https://player.vimeo.com/video/${video_id}?title=0&byline=0&portrait=0`
                    "
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                    :id="video_tag_id"
                ></iframe>
            </div>
        </template>
        <div class="video-play-wrapper">
            <div
                class="play-img-wrapper"
                :style="`background-image: url(${video_img})`"
            ></div>
            <template v-if="video_id">
                <div class="play-btn-wrapper" v-in-viewport.once>
                    <button
                        class="btn play-btn fade-item"
                        :style="
                            btn_color
                                ? `background-color: ${btn_color}`
                                : '#FFF'
                        "
                        :data-id="video_tag_id"
                    >
                        <span class="icon icon-play ml-2"></span>
                    </button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import Player from "@vimeo/player";

export default {
    data() {
        return {
            isPlaying: false,
            imgVisible: true
        };
    },

    props: ["video_tag_id", "video_id", "video_img", "btn_color"],
    mounted() {
        if (this.video_id) {
            let vm = this;
            var videoPlayer = new Player(vm.video_tag_id);

            videoPlayer.on("play", function() {
                vm.isPlaying = true;
                vm.$emit("playing");
            });

            $(`[data-id="${vm.video_tag_id}"]`).on("click", function() {
                videoPlayer.play();
                vm.imgVisible = false;
            });

            videoPlayer.on("pause", function() {
                vm.isPlaying = false;
            });

            $(`[data-id="${vm.video_tag_id}-pause"]`).on("click", function() {
                videoPlayer.pause();
                vm.imgVisible = false;
            });
        }
    }
};
</script>
